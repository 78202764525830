var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { CaseApplyOperateService } from "~/services/domain-service/case-apply-operate.service";
import { Emit, Prop } from "vue-property-decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { UserService } from "~/services/manage-service/user.service";
import { PrincipalService } from "~/services/manage-service/principal.service";
import DataBox from "~/components/common/data-box.vue";
var AssistDoEnd = /** @class */ (function (_super) {
    __extends(AssistDoEnd, _super);
    function AssistDoEnd() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.assistselectionList = [];
        _this.nowNode = null;
        _this.caseList = []; // 案件数组
        _this.manualModel = {
            batchNum: "",
            closeDate: "",
            companyCode: "",
            type: "",
        };
        _this.loading = {
            state: false,
        };
        _this.assistDataSet = [];
        _this.SearchModel = {};
        return _this;
    }
    AssistDoEnd.prototype.refreshList = function () { };
    AssistDoEnd.prototype.close = function () { };
    AssistDoEnd.prototype.approvedMeth = function (rowData) { };
    /**
     * 选择点变化
     */
    AssistDoEnd.prototype.getCheckedNodes = function (item) {
        this.nowNode = item;
        this.refreshData();
    };
    /**
     * 刷新
     */
    AssistDoEnd.prototype.refresh = function (caseList) {
        this.caseList = caseList;
        // this.nowNode = null
        // let tree: any = this.$refs.tree
        // tree.reset()
    };
    /**
     * 刷新用户列表
     */
    AssistDoEnd.prototype.refreshData = function () {
        var _this = this;
        var SearchModel = Object.assign(this.SearchModel, this.assistModel);
        SearchModel.assistFlags = ["OFFSITE_PHONE_ASSIST", "OFFSITE_OUT_ASSIST"]; //异地电话协助 OFFSITE_PHONE_ASSIST   异地外访协助  OFFSITE_OUT_ASSIST
        SearchModel.approvalStatus = "ASSIST_COMPLETED"; //办结
        this.loading.state = true;
        this.caseApplyOperateService.assistCaseApproveSearch(this.SearchModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.assistDataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    //审批操作
    AssistDoEnd.prototype.approvedAssistMeth = function (scope) {
        var rowData = scope.row;
        this.approvedMeth(rowData);
    };
    __decorate([
        Dependencies(SortService)
    ], AssistDoEnd.prototype, "sortService", void 0);
    __decorate([
        Dependencies(PageService)
    ], AssistDoEnd.prototype, "pageService", void 0);
    __decorate([
        Dependencies(UserService)
    ], AssistDoEnd.prototype, "userService", void 0);
    __decorate([
        Dependencies(PrincipalService)
    ], AssistDoEnd.prototype, "principalService", void 0);
    __decorate([
        Dependencies(CaseApplyOperateService)
    ], AssistDoEnd.prototype, "caseApplyOperateService", void 0);
    __decorate([
        Emit("refreshList")
    ], AssistDoEnd.prototype, "refreshList", null);
    __decorate([
        Emit("close")
    ], AssistDoEnd.prototype, "close", null);
    __decorate([
        Emit("approvedMeth")
    ], AssistDoEnd.prototype, "approvedMeth", null);
    __decorate([
        Prop()
    ], AssistDoEnd.prototype, "assistModel", void 0);
    AssistDoEnd = __decorate([
        Component({
            components: {
                DataBox: DataBox,
            },
        })
    ], AssistDoEnd);
    return AssistDoEnd;
}(Vue));
export default AssistDoEnd;
