import { render, staticRenderFns } from "./assist-approved.vue?vue&type=template&id=444642fd"
import script from "./assist-approved.vue?vue&type=script&lang=ts"
export * from "./assist-approved.vue?vue&type=script&lang=ts"
import style0 from "./assist-approved.vue?vue&type=style&index=0&id=444642fd&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports