var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { businessService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
var CaseApplyManageService = /** @class */ (function () {
    function CaseApplyManageService() {
    }
    /**
     * 留案申请查询
     */
    CaseApplyManageService.prototype.getProcessApplicationByFlowType = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.caseApplyManageController.getProcessApplicationByFlowType,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 发起流程申请
     */
    CaseApplyManageService.prototype.caseApplyOperated = function (data, loading) {
        return this.netService.send({
            server: businessService.caseApplyManageController.caseApplyOperated,
            data: data,
            loading: loading,
        });
    };
    /**
     * 审批操作
     */
    CaseApplyManageService.prototype.caseApplyApprove = function (data, loading) {
        return this.netService.send({
            server: businessService.caseApplyManageController.caseApplyApprove,
            data: data,
            loading: loading,
        });
    };
    /**
     * 协调管理查外访案件
     */
    CaseApplyManageService.prototype.searchAssistCase = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.caseApplyManageController.searchAssistCase,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 各类审批查询
     */
    CaseApplyManageService.prototype.getProcessApproveMessage = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.caseApplyManageController.getProcessApproveMessage,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 各类审批查询
     */
    CaseApplyManageService.prototype.getProcessFlowNodes = function (processApplicationId) {
        return this.netService.send({
            server: businessService.caseApplyManageController.getProcessFlowNodes,
            data: {
                processApplicationId: processApplicationId,
            },
        });
    };
    /**
     * 批量审批
     */
    CaseApplyManageService.prototype.approveByBatch = function (data, loading) {
        return this.netService.send({
            server: businessService.caseApplyManageController.approveByBatch,
            data: {
                approveRequestList: data,
            },
            loading: loading,
        });
    };
    /**
     * 信函打印
     */
    CaseApplyManageService.prototype.getLetterPrint = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.caseApplyManageController.getLetterPrint,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 更改信函打印状态
     */
    CaseApplyManageService.prototype.changePrintStatus = function (data, loading) {
        return this.netService.send({
            server: businessService.caseApplyManageController.changePrintStatus,
            data: {
                processApplicationIds: data,
            },
            loading: loading,
        });
    };
    __decorate([
        Inject(NetService)
    ], CaseApplyManageService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], CaseApplyManageService.prototype, "getProcessApplicationByFlowType", null);
    __decorate([
        Debounce()
    ], CaseApplyManageService.prototype, "caseApplyOperated", null);
    __decorate([
        Debounce()
    ], CaseApplyManageService.prototype, "caseApplyApprove", null);
    __decorate([
        Debounce()
    ], CaseApplyManageService.prototype, "searchAssistCase", null);
    __decorate([
        Debounce()
    ], CaseApplyManageService.prototype, "getProcessApproveMessage", null);
    __decorate([
        Debounce()
    ], CaseApplyManageService.prototype, "getProcessFlowNodes", null);
    __decorate([
        Debounce()
    ], CaseApplyManageService.prototype, "getLetterPrint", null);
    return CaseApplyManageService;
}());
export { CaseApplyManageService };
