var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { AssistCollectionCaseService } from "~/services/domain-service/assist-collection-case.service";
import { Emit, Prop } from "vue-property-decorator";
import { SortService } from "~/utils/sort.service";
import { PageService } from "~/utils/page.service";
import { UserService } from "~/services/manage-service/user.service";
import DataBox from "~/components/common/data-box.vue";
var AssistManageFixed = /** @class */ (function (_super) {
    __extends(AssistManageFixed, _super);
    function AssistManageFixed() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.nowNode = null;
        _this.caseList = []; // 案件数组
        _this.assistDataSet = [];
        _this.manualModel = {
            batchNum: "",
            closeDate: "",
            companyCode: "",
            type: "",
        };
        _this.searchModel = {
            assistStatus: "",
            assistLocalFlag: "OFFSITE_OUT_ASSIST",
        };
        return _this;
    }
    AssistManageFixed.prototype.refreshList = function () { };
    AssistManageFixed.prototype.close = function () { };
    /**
     * 刷新
     */
    AssistManageFixed.prototype.refresh = function (caseList) {
        this.caseList = caseList;
        // this.nowNode = null
        // let tree: any = this.$refs.tree
        // tree.reset()
    };
    /**
     * 刷新用户列表
     */
    AssistManageFixed.prototype.refreshData = function () {
        var _this = this;
        var searchModel = Object.assign(this.searchModel, this.assistModel);
        searchModel.assistStatus = "ASSIST_COMPLETED"; //已完成
        this.assistCollectionCaseService.searchAssistCase(searchModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.assistDataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    __decorate([
        Dependencies(SortService)
    ], AssistManageFixed.prototype, "sortService", void 0);
    __decorate([
        Dependencies(PageService)
    ], AssistManageFixed.prototype, "pageService", void 0);
    __decorate([
        Dependencies(UserService)
    ], AssistManageFixed.prototype, "userService", void 0);
    __decorate([
        Dependencies(AssistCollectionCaseService)
    ], AssistManageFixed.prototype, "assistCollectionCaseService", void 0);
    __decorate([
        Emit("refreshList")
    ], AssistManageFixed.prototype, "refreshList", null);
    __decorate([
        Emit("close")
    ], AssistManageFixed.prototype, "close", null);
    __decorate([
        Prop()
    ], AssistManageFixed.prototype, "selectionList", void 0);
    __decorate([
        Prop()
    ], AssistManageFixed.prototype, "assistModel", void 0);
    AssistManageFixed = __decorate([
        Component({
            components: {
                DataBox: DataBox,
            },
        })
    ], AssistManageFixed);
    return AssistManageFixed;
}(Vue));
export default AssistManageFixed;
